import React, { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { SearchIcon } from '../../assets/svgs/Icons';

const BottomSearchKeywordOptions = ({
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  setPreviousSelectedCategoryOptions,
  handleApplyFilters
}: any) => {
  const [searchText, setSearchText] = useState('');

  const handleSearch = (
    event: React.KeyboardEvent<HTMLDivElement>,
    isSearchIconClicked: boolean
  ) => {
    if (
      (event.key === 'Enter' || isSearchIconClicked) &&
      searchText &&
      !selectedCategoryOptions?.filter_keywords?.includes(searchText)
    ) {
      let formattedFilters: any = {};
      if (selectedCategoryOptions?.filter_keywords) {
        formattedFilters = {
          ...selectedCategoryOptions,
          filter_keywords: [searchText, ...selectedCategoryOptions.filter_keywords]
        };
      } else {
        formattedFilters = { ...selectedCategoryOptions, filter_keywords: [searchText] };
      }

      setSearchText('');
      setSelectedCategoryOptions(formattedFilters);
      setPreviousSelectedCategoryOptions(formattedFilters);
      handleApplyFilters(formattedFilters);
    }
  };

  return (
    <TextField
      placeholder='Search PDCO'
      value={searchText}
      onChange={(ev: any) => setSearchText(ev.target.value)}
      onKeyDown={ev => handleSearch(ev, false)}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <SearchIcon
              onClick={(ev: any) => handleSearch(ev, true)}
              sx={{
                color: 'gray.lightVariant4',
                cursor: 'pointer',
                transform: 'rotate(270deg)',
                fontSize: 14
              }}
            />
          </InputAdornment>
        ),
        sx: {
          borderRadius: 8,
          border: 'none',
          boxShadow: 'none',
          fontSize: 14,
          width: 281,
          height: 31
        }
      }}
      sx={{
        my: 1,
        backgroundColor: 'white.main',
        borderRadius: 8
      }}
    />
  );
};

export default React.memo(BottomSearchKeywordOptions);
